.settingmodel{
    &.open{
        display: unset;
    }
    display: none;
    position: absolute;
    right: 18px;
    top: 43px;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50), inset 0 1px 3px 0 rgba(0,0,0,0.50);
    width: 239px;
    height: 370px;
    background-color: #232B50;
    padding: 20px;
    color: #ffffff;
    .personInfo{
        text-align: center;
        border-bottom: 1px solid #ffffff;
        padding: 8px 0;
        .icon{
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        .name{
            margin: 0;
            font-size: 20px;
            font-weight: 700;
        }
        .setting-title{
            font-size: 14px;
            font-weight: 700;
        }
    }
    .settings{
        display: block;
        padding: 30px 0 20px 0;
        >a{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ffffff;
            color: #ffffff;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 20px;
            font-weight: 700;
            cursor: pointer;
        }
        >.version{
            margin: 0;
            text-align: center;
            font-size: 14px;
            line-height: 2;
            font-weight: 500;
        }
    }
}