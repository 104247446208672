@charset "UTF-8";
.line-notify-setting {
  margin-top: 25px;
  border-radius: 3px; }
  @media screen and (min-width: 320px) {
    .line-notify-setting {
      padding: 10px 8px; } }
  @media screen and (min-width: 768px) {
    .line-notify-setting {
      padding: 10px 15px; } }
  .line-notify-setting.close .onoffswitch {
    opacity: 0.5; }
  .line-notify-setting .icon-info:before {
    font-size: 1.125rem; }
  .line-notify-setting .text {
    margin: 0 0 0 8px;
    font-size: 1.125rem;
    padding-right: 7px; }
  .line-notify-setting .onoffswitch {
    margin-left: auto;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
    @media screen and (min-width: 320px) {
      .line-notify-setting .onoffswitch {
        width: 53px; } }
    @media screen and (min-width: 360px) {
      .line-notify-setting .onoffswitch {
        width: 45px; } }
    @media screen and (min-width: 768px) {
      .line-notify-setting .onoffswitch {
        width: 87px; } }
    @media screen and (min-width: 800px) {
      .line-notify-setting .onoffswitch {
        width: 70px; } }
    .line-notify-setting .onoffswitch .onoffswitch-checkbox {
      display: none; }
    .line-notify-setting .onoffswitch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
      margin-left: 0; }
    .line-notify-setting .onoffswitch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch.on {
      right: 0px; }
    .line-notify-setting .onoffswitch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch.off {
      left: 0px; }
    .line-notify-setting .onoffswitch .onoffswitch-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 2px solid #FFFFFF;
      border-radius: 20px; }
      .line-notify-setting .onoffswitch .onoffswitch-label.close {
        cursor: unset; }
      .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s  ease-in 0s; }
        .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner.on:before {
          display: flex; }
        .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner.on:after {
          display: none; }
        .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner.off:before {
          display: none; }
        .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner.off:after {
          display: flex;
          justify-content: flex-end; }
        .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner:before, .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner:after {
          float: left;
          width: 50%;
          height: 24px;
          padding: 0;
          align-items: center;
          line-height: 30px;
          font-size: 1.125rem;
          color: white;
          font-family: Trebuchet, Arial, sans-serif;
          font-weight: bold;
          box-sizing: border-box; }
        .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner:before {
          content: "";
          padding-left: 12px;
          background-color: #2a4b8d;
          color: #FFFFFF; }
          @media screen and (min-width: 768px) {
            .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner:before {
              content: "開啟"; } }
        .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner:after {
          content: "";
          padding-right: 12px;
          background-color: #EEEEEE;
          color: #999999;
          text-align: right; }
          @media screen and (min-width: 768px) {
            .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-inner:after {
              content: "關閉"; } }
      .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
        display: block;
        margin: 5px;
        background: #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 48px;
        right: 45px;
        border: 2px solid #FFFFFF;
        border-radius: 20px;
        transition: all 0.1s ease-in 0s; }
        @media screen and (min-width: 320px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            width: 47%; } }
        @media screen and (min-width: 360px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            width: 42%; } }
        @media screen and (min-width: 768px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            width: 25%; } }
        @media screen and (min-width: 320px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            left: 11px; } }
        @media screen and (min-width: 360px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            left: 14px; } }
        @media screen and (min-width: 375px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            left: 16px; } }
        @media screen and (min-width: 411px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            left: 16px; } }
        @media screen and (min-width: 768px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            left: 41px; } }
        @media screen and (min-width: 800px) {
          .line-notify-setting .onoffswitch .onoffswitch-label .onoffswitch-switch {
            left: 42px; } }

@media screen and (max-width: 320px) {
  .smallIcon {
    font-size: 14px; } }
