/*phone*/
@media only screen
  and (max-width: 639px) {
  .dashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(48, 20px);
  }

  .dashboard-booked {
    display: none;
  }

  .dashboard-capacity {
    display: none;
  }

  .dashboard-locationSummary {
    display: none;
  }

  .stats-slick {
    width: 100vw;
    grid-row: 1 / span 22;
  }

  .dashboard-booked-slick {
    width: 90vw;
    min-height: 197px;
  }

  .dashboard-capacity-slick {
    width: 90vw;
    height: 263px;
  }

  .dashboard-progress-slick {
    width: 90vw;
    height: 460px
  }

  .dashboard-location-slick {
    width: 90vw;
    height: 460px
  }

  .dashboard-progress-slick {
    width: 90vw !important;
  }

  .dashboard-new-cars {
    grid-row: 27 / span 20;
    margin: 0 1.3rem;
    width: 90vw;
  }

  .dashboard-overdue-alert {
    grid-row: 48 / span 12;
    margin: 0 1.3rem;
    width: 90vw;
  }

  .dashboard-deliver-alert {
    grid-row: 61 / span 12;
    margin: 0 1.3rem;
    width: 90vw;
  }
}

/*iPad*/
@media only screen
  and (max-width: 768px) and (min-width: 640px) {
  .dashboard {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(58, 20px);
    grid-column-gap: 2rem;
    padding: 2rem;
  }

  .dashboard-booked {
    grid-column: 1 / span 3;
    grid-row: 1 / span 10;
  }

  .dashboard-locationSummary {
    grid-column: 1 / span 6;
    grid-row: 26 / span 23;
  }  

  .dashboard-capacity {
    grid-column: 1 / span 3;
    grid-row: 12 / span 13;
  }

  .stats-slick {
    display: none;
  }

  .dashboard-booked-slick {
    display: none;
  }

  .dashboard-capacity-slick {
    display: none;
  }

  .dashboard-location-slick {
    display: none;
  }

  .dashboard-progress-slick {
    display: none;
  }


  .dashboard-new-cars {
    grid-column: 4 / span 3;
    grid-row: 1 / span 24;
  }

  .dashboard-deliver-alert {
    grid-column: 4 / span 3;
    grid-row: 50 / span 12;
  }

  .dashboard-overdue-alert {
    grid-column: 1 / span 3;
    grid-row: 50 / span 12;
  }
}

/*iPad tap*/
@media only screen
  and (max-width: 1024px) and (min-width: 769px) {
    .dashboard {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(65, 20px);
      grid-column-gap: 2rem;
      padding: 2rem;
    }
  
    .dashboard-booked {
      grid-column: 1 / span 3;
      grid-row: 1 / span 10;
    }
  
    .dashboard-locationSummary {
      grid-column: 1 / span 6;
      grid-row: 26 / span 30;
    }    
  
    .dashboard-capacity {
      grid-column: 1 / span 3;
      grid-row: 12 / span 13;
    }
  
    .stats-slick {
      display: none;
    }
  
    .dashboard-booked-slick {
      display: none;
    }
  
    .dashboard-capacity-slick {
      display: none;
    }
  
    .dashboard-location-slick {
      display: none;
    }
  
    .dashboard-progress-slick {
      display: none;
    }
  
  
    .dashboard-new-cars {
      grid-column: 4 / span 3;
      grid-row: 1 / span 24;
    }
  
    .dashboard-deliver-alert {
      grid-column: 4 / span 3;
      grid-row: 57 / span 12;
    }
  
    .dashboard-overdue-alert {
      grid-column: 1 / span 3;
      grid-row: 57 / span 12;
    }
  }

  /*web*/
@media only screen
  and (min-width: 1025px) {
  .dashboard {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(50, 20px);
    grid-column-gap: 1rem;
    padding: 2rem;
  }

  .dashboard-booked {
    grid-column: 1 / span 3;
    grid-row: 1 / span 11;
  }

  .dashboard-locationSummary {
    grid-column: 1 / span 6;
    grid-row: 13 / span 36;
  }

  .dashboard-capacity {
    grid-column: 4 / span 3;
    grid-row: 1 / span 11;
  }

  .stats-slick {
    display: none;
  }

  .dashboard-booked-slick {
    display: none;
  }

  .dashboard-capacity-slick {
    display: none;
  }

  .dashboard-location-slick {
    display: none;
  }

  .dashboard-progress-slick {
    display: none;
  }

  .dashboard-new-cars {
    grid-column: 7 / span 3;
    grid-row: 1 / span 20;
  }

  .dashboard-deliver-alert {
    grid-column: 7 / span 3;
    grid-row: 37 / span 14;
  }

  .dashboard-overdue-alert {
    grid-column: 7 / span 3;
    grid-row: 22 / span 14;
  }

}