@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?i22qo7');
  src:  url('fonts/icomoon.eot?i22qo7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?i22qo7') format('truetype'),
    url('fonts/icomoon.woff?i22qo7') format('woff'),
    url('fonts/icomoon.svg?i22qo7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-line:before {
  content: "\e913";
}
.icon-ic_dashboard:before {
  content: "\e900";
}
.icon-ic_reservation:before {
  content: "\e901";
}
.icon-ic_add:before {
  content: "\e902";
}
.icon-ic_arrow_forward:before {
  content: "\e903";
}
.icon-ic_clear:before {
  content: "\e904";
}
.icon-ic_error_outline:before {
  content: "\e905";
}
.icon-ic_error:before {
  content: "\e906";
}
.icon-ic_import:before {
  content: "\e907";
}
.icon-ic_profile:before {
  content: "\e908";
}
.icon-ic_report_problem:before {
  content: "\e909";
}
.icon-ic_ticket:before {
  content: "\e90a";
}
.icon-ic_working:before {
  content: "\e90b";
}
.icon-ic_offline_pin:before {
  content: "\e90c";
}
.icon-ic_reception:before {
  content: "\e90d";
}
.icon-ic_detailing:before {
  content: "\e90e";
}
.icon-ic_customer_here:before {
  content: "\e90f";
}
.icon-ic_customer_not_here:before {
  content: "\e910";
}
.icon-ic_customer_unknown:before {
  content: "\e911";
}
.icon-pencil:before {
  content: "\e912";
}
.icon-bullhorn:before {
  content: "\e91a";
}
.icon-files-empty:before {
  content: "\e925";
}
.icon-ic_coin-dollar:before {
  content: "\e93b";
}
.icon-phone:before {
  content: "\e942";
}
.icon-location:before {
  content: "\e947";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-upload3:before {
  content: "\e9c8";
}
.icon-ic_eye:before {
  content: "\e9ce";
}
.icon-ic_star-full:before {
  content: "\e9d9";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
