.border_area{
    border: 1px solid #e0e0e0;
    box-shadow: 0px 2px 10px -4px rgb(116, 114, 114);
}
.title{
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    &.office{
        color: #606f7b;
        padding: 20px 0 10px 0;
    }
    &.records{
        color: #606f7b;
        padding-bottom: 10px;
    }
}

.cruisys_title_area{
    background-color: rgb(33, 42, 82);
    display: flex;
    justify-content: space-between;
    align-items : center;
    padding: 0.5rem 15px;
    height: 50px;
    @media screen and (min-width: 768px){
        padding: 0.5rem 30px;
    }
    position: sticky;
    top: 0;
    .cruisys_link{
        font-size: 20px;
        color:#fff;
        font-weight: 700;
        display: flex;
        align-items: center;
    }
    .cruisys_title{
        font-size: 20px;
        color:#fff;
        margin: 0 0 0 auto;
        font-weight: 700;
    }
}


.area{
    font-size: 20px;
    cursor: pointer;
    width: 44%;
    height: 50%;
    @media screen and (min-width: 768px) {
        width: 15%;
        height: 100%;
    }
    &.open{
        color: #fff;
        background-color: #1E77CC;
    }
    &.close{
        color: #1E77CC;
        border: 4px solid  #1E77CC;
    }
}

.office_area{
    height: 398px;
    @media screen and (min-width: 768px){
        height: 228px;
    }
    .explain{
        font-size: 16px;
        padding:0 14px 20px 14px;
        margin: 0;
    }
}

.each_area{
    height: 230px;
    @media screen and (min-width: 768px) {
        height: 100px;
    }
}

.records_area{
    height: 70vh;
    //overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px 5px;
    @media screen and (min-width: 768px){
        padding: 20px 50px;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}
.each_record{
    font-size: 16px;
    margin: 0;
    color: #000000;
}
