body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4 !important;
}

.ticket-card-image {
  width: 100%;
  height: 100%;
}

.ticket-card-image.default-car{
  background-size: 80%;
}

@media screen and (min-width: 410px){
  .ticket-card-image.default-car{
    background-size: 75%;
  }
}

@media screen and (min-width: 768px){
  .ticket-card-image.default-car{
    background-size: 70%;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.better-h-screen {
  height: 100vh;
  height: calc(var(--vh, 1vh)*100);
}

.lineContacts {
  margin: 20px 0 0 0;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(50px, auto);
}

.ticketCards {
  max-width: 940px;
  margin: 0 20px;
  display: grid;
  grid-gap: 10px;
}

/* no grid support? */

.ticketCards {
  display: flex;
  flex-wrap: wrap;
}

.ticketCards {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(150px, auto);
}

/* tablet */
@media only screen
  and (max-width: 1024px) {
  .lineContacts {
    display: grid;
    grid-gap: 10px;
    margin: 20px 0 0 0;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(50px, auto);
  }
}

/* phone */
@media only screen
  and (max-width: 639px) {
  .ticketCards {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(150px, auto);
  }

  .lineContacts {
    display: grid;
    grid-gap: 10px;
    margin: 20px 28px 0 28px;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(50px, auto);
  }

  .sidebar {
    position: static;
    width: 100%;
  }

  #advanced-search-fields {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(20, 33px);
  }

  .filter-button {
    width: 30%;
  }

  .new-form-sub-section {
    display: grid;
    grid-template-rows: repeat(15, minmax(46px, auto) );
    grid-template-columns: 100%;
  }

  #new-ticket-assignee-input-label {
    grid-row: 1 / span 1;
  }

  #new-ticket-booked-input-label {
    grid-row: 3 /span 1;
  }

  #new-ticket-booked-input {
    grid-row: 4 / span 1;
  }

  #new-ticket-service-input-label {
    grid-row: 5 / span 1;
  }

  #new-ticket-service-input {
    grid-row: 6 / span 1;
  }

  #new-ticket-stayin-input-label {
    grid-row: 7 / span 1;
  }

  #new-ticket-stayin-input {
    grid-row: 8 / span 1;
  }

  .inactive-tab-left {
    box-shadow: inset -4px -4px 5px 0px #a8a8a885;
  }

  .inactive-tab-right {
    box-shadow: inset 4px -4px 5px 0px #a8a8a885;
  }

  .subtoolbar {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 48px 48px;
  }

  .inputfile + label {
    color: rgba(0,0,0,.87);
    background-color: white;
    border: 1px solid #dee1e5;
    padding: 13.75px;
    line-height: 17.5px;
    width: 100%;
    height: 100%;
    font-size: 1.25rem;
    margin: 0 .25em 0 0;
    border-radius: .28571429rem;
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  
  #detail-ticket-assignee-input-label {
    grid-row: 2 / 3;
  }

  #detail-ticket-booked-input-label {
    grid-row: 4 / 5;
  }

  #detail-ticket-booked-input {
    grid-row: 4 / 5;
  }

  #detail-ticket-service-input-label {
    grid-row: 5 / 6;
  }

  #detail-ticket-service-input {
    grid-row: 5 / 6;
  }

  #detail-ticket-stayin-input-label {
    grid-row: 6 / 7;
  }

  #detail-ticket-stayin-input {
    grid-row: 6 / 7;
  }

  #detail-form-vin-input {
    width: 100%;
  }
}

.borderbox {
  box-sizing: border-box;
}


.panel {
  /* needed for the flex layout*/
  margin-left: 5px;
  margin-right: 5px;
  flex: 1 1 200px;
}

.ticketCards > * {
  border-radius: 5px;
  font-size: 150%;
  margin-bottom: 10px;
}


/* We need to set the margin used on flex items to 0 as we have gaps in grid.  */

@supports (display: grid) {
  .ticketCards > * {
    margin: 0;
  }
}

.select-css {
  display: inline-block;
  font-weight: 700;
  color: #444;
  padding: .6em .1em .5em .8em;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight:normal;
}

.shadow-right {
  position: relative;
  box-shadow: 5px 0 5px -2px #ccc;
}

.timeline-sep {
  position: relative;
}

.timeline-sep::after {
  content:"";
  background: #444444;
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 70%;
}

input::-webkit-input-placeholder {
  color: #707070 !important;
}

.inner-left-shadow {
  box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.3);
}

button:disabled {
  background-color: grey;
}

.c3-legend-item, .c3-gauge-value, .c3-chart-arcs-title {
  font-size: 14px !important;
}

.c3-chart-arc text {
  font-size: 14px !important;
}

.c3-chart-arcs-gauge-max, .c3-chart-arcs-gauge-min {
  visibility: hidden !important;
}

@media only screen
  and (min-width: 640px) {
  .slick-dots {
    bottom: -15px !important;
  }
}

/* disable double-tap zoom for block elements or elements with known height/width */
* {
  touch-action: manipulation;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

h1, h2, h3, h4, h5 {
  margin-bottom: 1rem;
}

.is-invalid-field {
  border : 2px solid #e71f19;
}

.is-invalid-text {
  margin-top: 3px;
  color: #e71f19;
}

.is-warning-text {
  margin-top: 3px;
  color: #ff9000;
}

@media only screen
  and (min-width: 640px) {
  #advanced-search-fields {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto 1fr;
    grid-template-rows: 33px 33px 33px 33px;
    grid-row-gap: 1.25rem;
    grid-column-gap: 0.5rem;
  }

  .sidebar {
    height: calc(var(--vh, 1vh)*100);

    position: fixed;
    top: 0;
    right: 0;
    overflow: scroll;
    padding-top: 4.5rem;
  }

  .new-form-section {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 2rem;
  }

  .new-form-sub-section {
    display: grid;
    grid-template-columns: 40px 1fr 40px 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;
  }

  .new-form-vin-input {
    grid-column: 2 / span 3;
  }

  .ticket-detail-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
  }

  .inputfile + label {
    color: rgba(0,0,0,.87);
    background-color: white;
    border: 1px solid #dee1e5;
    padding: 13.75px;
    line-height: 17.5px;
    width: 50px;
    height: 46.75px;
    margin: 0 .25em 0 0;
    border-radius: .28571429rem;
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
  }  

  .inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .ticket-detail-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
  }
}

.disabled-link {
  pointer-events: none;
}

@media only screen and (min-width: 640px){
  .rwdcar-image{
    width: 100%;
    max-width: 300px;
    height: auto;
  }
}

@media only screen and (max-width: 640px){
  .rwdcar-image{
    width: 300px;
    height: 250px;
  }
}

.selfcome{
  font-weight: 700;
  color: #444;
  padding: .6em .1em .5em .8em;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}